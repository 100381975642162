
// @ts-nocheck
import axios, { AxiosError, AxiosResponse } from "axios";
import Vue from "vue";
import { mapGetters, mapMutations } from "vuex";

import Flag from "@/components/Flag.vue";

import flags from "@/mixins/flags";

import {
  SET_COUNTRY_CODE_IP,
  SET_COUNTRY_CODE_ROUTE,
  SET_COUNTRY_CODE_USER_SELECTED,
} from "@/store/types/mutations";

import { redirectToVersion } from "@/utils/utils";

import { IP_INFO_TOKEN } from "@/app.config";

export default Vue.extend({
  components: {
    Flag,
  },
  mixins: [flags],
  data() {
    return {
      isExpanded: false,
    };
  },
  computed: {
    ...mapGetters(["countryCode"]),
    onHomeRoute() {
      return this.$route.name?.startsWith("home");
    },
    routeCountryCode() {
      if (!this.onHomeRoute) return null;
      const pathWithoutPrecedingSlash = this.$route.path.slice(1);
      const countryCode = pathWithoutPrecedingSlash;
      return countryCode;
    },
  },
  watch: {
    countryCode: {
      handler(newVal) {
        if (newVal && this.onHomeRoute && this.routeCountryCode !== newVal) {
          redirectToVersion(newVal);
        }
      },
    },
    $route: {
      handler() {
        this.handlePath();
      },
      immediate: true,
    },
  },
  created() {
    this.getIpLocation();
  },
  methods: {
    ...mapMutations([
      SET_COUNTRY_CODE_IP,
      SET_COUNTRY_CODE_ROUTE,
      SET_COUNTRY_CODE_USER_SELECTED,
    ]),
    clickedOutside() {
      this.isExpanded = false;
    },
    getIpLocation() {
      axios
        .get(`https://ipinfo.io/json?token=${IP_INFO_TOKEN}`)
        .then(async (successfulResponse: AxiosResponse) => {
          const countryCode = successfulResponse?.data?.country?.toLowerCase();

          if (countryCode) this.SET_COUNTRY_CODE_IP(countryCode);

          return successfulResponse;
        })
        .catch((error: AxiosError) => {
          console.error(error);
          return error.response;
        });
    },
    handlePath() {
      if (this.$route.name === "home-country-index") {
        this.SET_COUNTRY_CODE_ROUTE(null);
      } else if (this.routeCountryCode) {
        this.SET_COUNTRY_CODE_ROUTE(this.routeCountryCode);
      }
    },
    userSetCountryCode(countryCode) {
      this.SET_COUNTRY_CODE_USER_SELECTED(countryCode);
      redirectToVersion(countryCode);
      this.isExpanded = false;
    },
  },
});
